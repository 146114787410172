import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Spinner from '../components/Spinner/Spinner';

import { BreadCrumbInterface } from '../components/BreadCrumbs/BreadCrumbs.types';
import PageHeader from '../components/PageHeader/PageHeader';

import useApi from '../hooks/useApi';
import OutputJsonText from '../components/OutputJsonText/OutputJsonText';

interface IAutocompleteStatus {
  headline: string;
  message: string;
}

function Autocomplete(): JSX.Element {
  const api = useApi();

  const params = useParams();

  const [autocompleteStatus, setAutocompleteStatus] =
    useState<IAutocompleteStatus>({
      headline: 'Task completion',
      message: 'In progress...',
    });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getTaskAutocomplete = (): void => {
      api
        .doRequest(
          `/task/auto-complete/${params.uuid}`, // DELETE ME 2c08bc89-93bc-482a-afa9-7e94d6930059
          {
            method: 'GET',
          }
        )
        .then((response) => {
          setAutocompleteStatus(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setAutocompleteStatus({
            headline: 'Task completion failed',
            message:
              err.response?.data?.message ?? 'An unknown error occurred.',
          });
          setIsLoading(false);
        });
    };
    getTaskAutocomplete();
  }, []);

  const breadcrumbs: Array<BreadCrumbInterface> = [
    {
      text: 'Home',
      to: '/',
    },
    {
      text: 'Task Completion',
    },
  ];

  return (
    <div className="relative">
      <PageHeader title="Task Completion" breadcrumbs={breadcrumbs} />
      {isLoading && <Spinner />}
      <div className="px-6 py-4 sm:px-0 sm:py-0">
        <h2 className="text-lg font-bold">{autocompleteStatus.headline}</h2>
        <OutputJsonText jsonText={autocompleteStatus.message} />
      </div>
    </div>
  );
}

export default Autocomplete;
