import { Navigate, useParams } from 'react-router-dom';

interface Props {
  to: string | ((params: any) => string);
  replace?: boolean;
}

export default function NavigateWithParams({
  to,
  replace = false,
}: Props): JSX.Element {
  const params = useParams();
  let toValue;
  if (typeof to === 'function') {
    toValue = to(params);
  } else {
    toValue = to;
  }
  return <Navigate to={toValue} replace={replace} />;
}
