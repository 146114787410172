import { useContext, useState } from 'react';

import { DownloadIcon, TrashIcon } from '@heroicons/react/outline';

import useApi from '../../hooks/useApi';
import { AppContext, AppContextValueType } from '../../store/app-context';
import { Props, DownloadTypes } from './DownloadFileButton.types';

function DownloadFileButton({
  text,
  fileName,
  downloadConfig,
  onDelete,
  contentType,
  apiEndPointForDownload,
  mode = DownloadTypes.URL,
  disabled = false,
  disabledMessage = '',
  title = 'Download file',
}: Props): JSX.Element {
  const api = useApi();
  const { notifyError, updateNotificationOpen } =
    useContext<AppContextValueType>(AppContext);

  const [isDownloading, setIsDownloading] = useState(false);
  const isDeletable = !!onDelete;

  let titleToUse = title;
  if (disabled && disabledMessage) {
    titleToUse = disabledMessage;
  }

  const doDownload = async (): Promise<void> => {
    setIsDownloading(true);
    if (downloadConfig?.notificationOnStart) {
      updateNotificationOpen(downloadConfig.notificationOnStart);
    }

    if (apiEndPointForDownload) {
      let url = apiEndPointForDownload;
      if (downloadConfig?.params) {
        url += `?${downloadConfig.params}`;
      }
      api
        .doRequest(url, { method: 'GET' })
        .then((response) => {
          setIsDownloading(false);

          const link = document.createElement('a');
          let file;

          switch (mode) {
            case DownloadTypes.URL:
              link.href = response.data;
              break;
            case DownloadTypes.CONTENT:
              file = new Blob([response.data], { type: contentType });
              link.href = URL.createObjectURL(file);
              break;
            default:
          }

          link.target = '_blank';
          link.download = fileName;
          link.click();

          // code below opened corrupt files (often blank pdfs) in combination with responseType: 'blob' on the axios request
          // const url = window.URL.createObjectURL(response.data);
          // const a = document.createElement('a');
          // a.href = url;
          // a.download = fileName;
          // document.body.appendChild(a);
          // a.click();
          // window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          setIsDownloading(false);
          // show confirmation notification
          notifyError(
            'File could not be downloaded',
            err?.response?.data?.message
          );
        });
    }
  };

  const onDeleteClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    if (isDeletable) {
      onDelete();
    }
  };

  return (
    <button
      type="button"
      onClick={doDownload}
      className="text-weknow-default text-sm cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
      disabled={disabled || isDownloading}
      title={titleToUse}>
      <div className="flex relative">
        {isDeletable && (
          <div onClick={onDeleteClick} aria-hidden title="Remove file">
            <TrashIcon className="w-5 mr-1" />
          </div>
        )}
        {!isDeletable && <DownloadIcon className="w-5 mr-1" />}
        <div>{text}</div>
        {isDownloading && (
          <div className="relative pl-8">
            <svg
              className="button-spinner orange ml-1 relative"
              viewBox="0 0 25 25">
              <circle
                className="path"
                cx="12.5"
                cy="12.5"
                r="10"
                fill="none"
                strokeWidth="2"
              />
            </svg>
          </div>
        )}
      </div>
    </button>
  );
}

export default DownloadFileButton;
