import { lazy, useContext, useEffect, useState } from 'react';
import { AppContextValueType, AppContext } from '../store/app-context';
import SideMenu from '../components/SideMenu/SideMenu';
import {
  MenuItemBackEndInterface,
  MenuItemFrontEndInterface,
} from '../interfaces/MenuItem.types';
import Notification from '../components/Notification/Notification';

const InProgressOverlay = lazy(
  () => import('../components/InProgressOverlay/InProgressOverlay')
);

/**
 * Filter out menu items that are not viewable on the current device
 */
const considerViewableMainMenuItems = (
  mainMenuItems: Array<MenuItemBackEndInterface>,
  isMobile: boolean | null
): Array<MenuItemFrontEndInterface> =>
  mainMenuItems.map((mainMenuItem) => {
    if (isMobile && mainMenuItem.mobile_device) {
      // use settins for mobile device
      return {
        name: mainMenuItem.name,
        ...mainMenuItem.mobile_device,
      };
    }
    // use settings for common device
    return {
      name: mainMenuItem.name,
      ...mainMenuItem.common_device,
    };
  });

/**
 * Returns a function that wraps the passed component with:
 * - SideMenu
 * - InProgressOverlay
 * - Notification
 * @returns {object} `withWrapper` function
 */
const useWCIWrapper = (): {
  withWrapper: (Component: JSX.Element) => JSX.Element;
} => {
  const [menuItems, setMenuItems] = useState<Array<MenuItemFrontEndInterface>>(
    []
  );
  const { appData, isMobile } = useContext<AppContextValueType>(AppContext);

  useEffect(() => {
    if (appData.mainMenuItems.length > 0) {
      const mainMenuItems = considerViewableMainMenuItems(
        appData.mainMenuItems,
        isMobile
      );
      setMenuItems(mainMenuItems);
    }
  }, [appData]);

  const withWrapper = (Component: JSX.Element): JSX.Element => (
    <>
      <SideMenu menuItems={menuItems}>{Component}</SideMenu>
      <InProgressOverlay />
      <Notification />
    </>
  );

  return { withWrapper };
};

export default useWCIWrapper;
