import { DownloadConfig } from '../PageHeader/PageHeader.types';

export enum DownloadTypes {
  URL = 'url',
  CONTENT = 'content',
}

export enum DownloadModes {
  PROFILES_CSV_FILE = 'profilesCsvFile',
}

export interface Props {
  text: string;
  fileName: string;
  downloadConfig?: DownloadConfig;
  apiEndPointForDownload: string;
  onDelete?: () => void;
  mode?: DownloadTypes;
  contentType?: string;
  title?: string;
  disabled?: boolean;
  disabledMessage?: string;
}
