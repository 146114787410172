import { ReactComponent as WeKnowLogoWhite } from '../assets/svg/WeKnow_logo_white.svg';
import { ReactComponent as WCIFoundationLogoWhite } from '../assets/svg/WCI_Foundation_logo.svg';
import { ReactComponent as WCITransformationLogoWhite } from '../assets/svg/WCI_Transformation_logo.svg';
import { ProjectLabels } from '../store/app-context';

const LogoSVGs: {
  [key: string]: JSX.Element;
} = {
  [ProjectLabels.WE_KNOW]: <WeKnowLogoWhite className="max-h-8 w-auto" />,
  [ProjectLabels.WCI_FOUNDATION]: (
    <WCIFoundationLogoWhite className="max-h-8 w-auto" />
  ),
  [ProjectLabels.WCI_TRANSFORMATION]: (
    <WCITransformationLogoWhite className="max-h-10 w-auto" />
  ),
};

export default LogoSVGs;
