import { useContext, useEffect } from 'react';

import { AppContext, AppContextValueType } from '../../store/app-context';
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';
import Props from './PageHeader.types';
import DownloadFileButton from '../DownloadFileButton/DownloadFileButton';
import { DownloadTypes } from '../DownloadFileButton/DownloadFileButton.types';

function PageHeader({
  title,
  breadcrumbs,
  downloadConfig,
}: Props): JSX.Element {
  const { updateHeaderTitle, resetHeader } =
    useContext<AppContextValueType>(AppContext);

  useEffect(() => {
    updateHeaderTitle(title);

    // cleanup header on unmount
    return () => {
      resetHeader();
    };
  }, []);

  return (
    <div className="mb-2">
      <div className="hidden md:block">
        <div className="py-4">
          <BreadCrumbs items={breadcrumbs} />
        </div>
        <div className="flex justify-between align-center">
          <div className="text-3xl font-bold mb-2">{title}</div>
          {downloadConfig?.apiEndPointForDownload && (
            <DownloadFileButton
              text={downloadConfig.buttonText}
              fileName={downloadConfig.fileName}
              downloadConfig={downloadConfig}
              apiEndPointForDownload={downloadConfig.apiEndPointForDownload}
              mode={DownloadTypes.CONTENT}
              contentType="text/csv"
              disabled={!!downloadConfig.disabledMessage?.length}
              disabledMessage={downloadConfig.disabledMessage}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PageHeader;
