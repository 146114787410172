/* This example requires Tailwind CSS v2.0+ */
import { useContext } from 'react';
import {
  AppContextValueType,
  AppContext,
  ProjectLabels,
} from '../../store/app-context';
import LogoSVGs from '../../constants/LogoSVGs';

export default function SideMenuLogo(): JSX.Element {
  const { currentProject } = useContext<AppContextValueType>(AppContext);
  const prjLabel = currentProject?.prj_label ?? ProjectLabels.WE_KNOW;

  return LogoSVGs[prjLabel];
}
