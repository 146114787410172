import Props from './OutputJsonText.types';

export default function OutputJsonText({
  jsonText,
  className = '',
}: Props): JSX.Element | null {
  if (!jsonText) {
    return null;
  }
  return (
    <div
      className={className}
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{
        __html: jsonText.replace(/(?:\r\n|\r|\n)/g, '<br>'),
      }}
    />
  );
}
