/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import { AppContext, AppContextValueType } from '../../store/app-context';
import Props from './SideMenu.types';
import SideMenuNavItems from './SideMenuNavItems';

export default function SideMenu({ children, menuItems }: Props): JSX.Element {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { headerTitle, headerIconButton } =
    useContext<AppContextValueType>(AppContext);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-weknow-darkest bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full">
                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-weknow-darker">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full"
                        onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* mobile menu */}
                  <SideMenuNavItems
                    menuItems={menuItems}
                    closeSidebar={() => setSidebarOpen(false)}
                  />
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 z-10">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-weknow-darker">
            <SideMenuNavItems menuItems={menuItems} />
          </div>
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white border-b border-gray-300">
            <div className="flex flex-row justify-between items-center">
              <button
                type="button"
                className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-weknow-darkest focus:outline-none"
                onClick={() => setSidebarOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                <MenuIcon className="h-7 w-7 text-black" aria-hidden="true" />
              </button>
              <p className="text-weknow-darkest font-semibold text-lg">
                {headerTitle}
              </p>
              <div className="w-12 text-right">
                {headerIconButton && (
                  <button
                    type="button"
                    onClick={headerIconButton.onClickHandler}
                    className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-weknow-darkest focus:outline-none">
                    <span className="sr-only">Open options</span>
                    <headerIconButton.icon
                      className="h-7 w-7 text-black"
                      aria-hidden="true"
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
          <main>
            <div className="max-w-5xl mx-auto sm:px-6 lg:px-8 pb-12">
              {children}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
