import { Link } from '../../helper/WCILink';
import Props from './BreadCrumbs.types';

export default function BreadCrumbs({ items }: Props): JSX.Element {
  return (
    <div className="flex breadcrumbs align-middle justify-start">
      {items.map((item) => {
        if (item.to) {
          return (
            <Link
              key={item.text}
              to={item.to}
              className="text-xs font-medium text-gray-400">
              {item.text}
            </Link>
          );
        }
        return (
          <div key={item.text} className="text-xs font-medium text-gray-400">
            {item.text}
          </div>
        );
      })}
    </div>
  );
}
